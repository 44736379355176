var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              "no-maximize": "",
                              title: "Specification",
                              expandable: "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c("AsyentFormSelectSimple", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload":
                                          _vm.specificationsReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "specificationName",
                                        "item-value": "id",
                                        label: "Specification",
                                        mandatory: "",
                                        name: "specificationName",
                                      },
                                      model: {
                                        value: _vm.formData["specificationId"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "specificationId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['specificationId']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c("asyent-button", {
                                      attrs: {
                                        id: "v-step-execute",
                                        "button-icon": "mdi-reload",
                                        "button-tooltip": "Execute",
                                        small: "",
                                        text: "",
                                      },
                                      on: {
                                        confirmed: _vm.executeTransformation,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c("asyent-button", {
                                      attrs: {
                                        id: "v-step-execute",
                                        "button-icon": "mdi-reload",
                                        "button-tooltip": "Edit",
                                        small: "",
                                        text: "",
                                      },
                                      on: { confirmed: _vm.editSpecification },
                                    }),
                                    _c(
                                      "v-dialog",
                                      {
                                        attrs: {
                                          fullscreen:
                                            _vm.$vuetify.breakpoint.smAndDown,
                                          persistent: "",
                                          scrollable: "",
                                          transition:
                                            "dialog-bottom-transition",
                                        },
                                        model: {
                                          value: _vm.specificationDialog,
                                          callback: function ($$v) {
                                            _vm.specificationDialog = $$v
                                          },
                                          expression: "specificationDialog",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-toolbar",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  "max-height": "56",
                                                },
                                              },
                                              [
                                                _c("v-toolbar-title", [
                                                  _vm._v("Specification"),
                                                ]),
                                                _c("v-spacer"),
                                                _c("asyent-button", {
                                                  attrs: {
                                                    "button-icon": "mdi-check",
                                                    "button-tooltip": "Update",
                                                    icon: "",
                                                    "requires-confirmation": "",
                                                  },
                                                  on: {
                                                    confirmed:
                                                      _vm.updateSpecification,
                                                  },
                                                }),
                                                _c("asyent-button", {
                                                  attrs: {
                                                    "button-icon": "mdi-close",
                                                    "button-tooltip": "Cancel",
                                                    icon: "",
                                                    "requires-confirmation": "",
                                                  },
                                                  on: {
                                                    confirmed: _vm.closeDialog,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass: "ma-0 pa-0",
                                                style: {
                                                  "max-height": _vm.$vuetify
                                                    .breakpoint.smAndUp
                                                    ? "500px"
                                                    : "",
                                                },
                                              },
                                              [
                                                _c("AsyentCodeArea", {
                                                  key: _vm.rand,
                                                  attrs: {
                                                    referefence:
                                                      "specification",
                                                    "view-mode": _vm.viewMode,
                                                    name: "specification",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataSpec[
                                                        "specification"
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataSpec,
                                                        "specification",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataSpec['specification']",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              "no-maximize": "",
                              title: "Document",
                              expandable: "",
                            },
                          },
                          [
                            _c("AsyentCodeArea", {
                              attrs: {
                                referefence: "document",
                                "view-mode": _vm.viewMode,
                                name: "document",
                              },
                              model: {
                                value: _vm.formData["document"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "document", $$v)
                                },
                                expression: "formData['document']",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              "no-maximize": "",
                              title: "Transformation",
                              expandable: "",
                            },
                          },
                          [
                            _c("AsyentCodeArea", {
                              attrs: {
                                referefence: "transformation",
                                name: "transformation",
                              },
                              model: {
                                value: _vm.formData["transformation"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "transformation", $$v)
                                },
                                expression: "formData['transformation']",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-row"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }